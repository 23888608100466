import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getDataSec, postDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const WithdrawalRequest = () => {

    const [list, setList] = useState([]);
    const [bankDetails, setBankDetails] = useState({});
    const [adjustAmount, setAdjustAmount] = useState(0);
    const [adjustRemarks, setAdjustRemarks] = useState("");
    const [adjustPhoneNo, setAdjustPhoneNo] = useState("");

    const getAccountDetails = async () => {
        try {
            const result = await getDataSec(`${baseURL}/deliveryboy/bankaccountDetails`);
            if (result.success) {
                setBankDetails(result.data);
            } else {
                toast.info(result.message);
            }

        } catch (error) {
            console.log(error);
            toast.error("Error in getting withdrawal requests");
        }
    }

    const getData = async () => {
        try {
            const result = await getDataSec(`${baseURL}/deliveryboy/withdrawalRequests`);
            if (result.success) {
                setList(result.list)
            } else {
                toast.info("No requests");
            }

        } catch (error) {
            console.log(error);
            toast.error("Error in getting withdrawal requests");
        }
    }

    const approveRequest = async (id) => {
        try {
            const result = await postDataSec(`${baseURL}/deliveryboy/acceptrequest`, {
                _id: id
            })
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error("Error");
            console.log(error);
        }
    }
    const rejectRequest = async (id) => {
        try {
            const result = await postDataSec(`${baseURL}/deliveryboy/rejectrequest`, {
                _id: id
            })
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error("Error");
            console.log(error);
        }
    }

    const adjustBalance = async (id) => {
        try {
            const result = await postDataSec(`${baseURL}/deliveryboy/adjustearning`, {
                "phoneNo": adjustPhoneNo,
                "type": adjustAmount < 0 ? "debit" : "credit",
                "amount": adjustAmount < 0 ? -adjustAmount : adjustAmount,
                "remarks": adjustRemarks
            })
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error("Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getData()
        getAccountDetails()
    }, [])

    return (
        <div style={{ padding: 20, display: 'flex', flexDirection: 'column', gap: 20 }}>

            <div style={{ backgroundColor: 'white', padding: 20, gap: 10, display: 'flex', justifyContent: 'space-between' }}>
                <h2>Adjust Earning</h2>
                <input type="text" placeholder="Amount" value={adjustAmount} onChange={(e) => { setAdjustAmount(e.target.value) }} />
                <input type="text" placeholder="Phone No" value={adjustPhoneNo} onChange={(e) => { setAdjustPhoneNo(e.target.value) }} />
                <input type="text" placeholder="Remarks" value={adjustRemarks} onChange={(e) => { setAdjustRemarks(e.target.value) }} />
                <button onClick={adjustBalance}>Adjust</button>
            </div>
            {
                bankDetails?.account_number ?
                    <div style={{ backgroundColor: 'white', padding: 20, gap: 10, display: 'flex', justifyContent: 'space-between' }}>
                        <p>Account Number : {bankDetails.account_number}</p>
                        <p>Bank Name : {bankDetails.bank_name}</p>
                        <p>Current Balance : Rs {bankDetails.balance.toFixed(2)}</p>
                    </div> : <></>
            }

            <Table style={{ backgroundColor: 'white', padding: 20 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Phone No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Floating Cash</TableCell>
                        <TableCell>Current Balance</TableCell>
                        <TableCell>Request Date</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, index) => (
                        <TableRow key={index} style={{}}>
                            <TableCell> {item.id}</TableCell>
                            <TableCell> {item.phoneNo}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell> {item.status}</TableCell>
                            <TableCell> {item.amount}</TableCell>
                            <TableCell> {item.floatingCash}</TableCell>
                            <TableCell> {item.currentBalance}</TableCell>
                            <TableCell> {new Date(item.created).toLocaleString()}</TableCell>
                            <TableCell>
                                {
                                    item.status === "pending" ?
                                        <><button onClick={() => { approveRequest(item._id) }} style={{ width: 120, height: 40, backgroundColor: 'rgb(68,142,247)', color: 'white', border: 0 }}>Approve</button>
                                            <button onClick={() => { rejectRequest(item._id) }} style={{ width: 120, height: 40, backgroundColor: 'rgb(68,142,247)', color: 'white', border: 0 }}>Reject</button></>
                                        : item.status
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div >
    );
}

export default WithdrawalRequest;
